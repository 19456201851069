import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";

import { DEFAULT_WEB3_CONFIG, web3ConfigReducer } from "redux/reducers/web3ConfigReducers";

const reducer = combineReducers({
  web3Config: web3ConfigReducer,
});

const initialState = {web3Config: DEFAULT_WEB3_CONFIG};

const middleware = [thunk];
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// export type RootState = ReturnType<typeof reducer>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
