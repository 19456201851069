import React from "react";
import { css, cx } from "@emotion/css";
import "animate.css";

import HeadTags from "components/HeadTags";

import MainBanner from "./components/MainBanner";
import CurrentStake from "./components/CurrentStake";
import Features from "./components/Features";
import CTA from "./components/CTA";
import Partner from "./components/Partner";
import IndustriesWeServe from "./components/IndustriesWeServe";

import "./public/css/style.scss";
import "./public/css/responsive.scss";
import { METADATA_SETTING } from "DollyApp/constants";

const Home = () => {
  return (
    <div className={cx("DD-Home", style)}>
      <HeadTags
        title="Home"
        titleWithPrefixBrand={METADATA_SETTING.title}
      />
      <MainBanner />
      <CurrentStake />
      <Features />
      <CTA />
      <IndustriesWeServe />
      <Partner />
    </div>
  );
};

export default Home;

const style = css``;
