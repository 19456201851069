import { EnumEnv } from "enums/env.enum";
import { getENV } from "env/env";

type EnvSetting = {
  dollyContractAddress: string;
  stETHContractAddress: string;
  wETHContractAddress: string;
  wstETHContractAddress: string;
};

export const ALL_CONTRACT_INFO: Record<EnumEnv, EnvSetting> = {
  [EnumEnv.Development]: {
    dollyContractAddress: "0xFCc90B4e1bdEf5Cd2c5E92DaeDe2caE2DaB616C3", // dolly-testnet
    stETHContractAddress: "0xae7ab96520de3a18e5e111b5eaab095312d7fe84", // dolly-testnet
    wETHContractAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", // dolly-testnet
    wstETHContractAddress: "0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0", // dolly-testnet
    // stETHContractAddress: "0xB770Dc3DA858d64306815922d1e2a2Ad68B36F8E",  // goerli
    // stETHContractAddress: "0x3e6cda2769bb92e9cba3b1f192b97efa4eeb564a", // sepolia
    // wETHContractAddress: "0x42a71137C09AE83D8d05974960fd607d40033499", // goerli
  },
  [EnumEnv.Production]: {
    dollyContractAddress: "0xF96d647f12ec15b461be3AC1F0F7ad50415FCbC5",
    stETHContractAddress: "0xae7ab96520de3a18e5e111b5eaab095312d7fe84",
    wETHContractAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    wstETHContractAddress: "0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0",
  }
};

const env = getENV();

export default ALL_CONTRACT_INFO[env];
