import React from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import { SiLinktree } from "react-icons/si";
import { RxDiscordLogo } from 'react-icons/rx';
import { css, cx } from "@emotion/css";
import "animate.css";

import { ReactComponent as Logo } from "assets/logo.svg";

import { MEDIA_SETTING } from "DollyApp/constants";
import { pageLinks } from "DollyApp/routes";
import { styleSettingColor } from "DollyApp/styles/variables.style";

const Footer = () => {
  return (
    <footer className={cx(style, "footer-area bg-f7fafd")} style={{ padding: "3rem 0" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="single-footer-widget">
              <Link to={pageLinks.home} className="header-link">
                <div className="logo">
                  <div className="header-logo">
                    <Logo />
                  </div>
                  <div>DefiDolly</div>
                </div>
              </Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="single-footer-widget pl-5">
              <h3>Features</h3>
              <ul className="list">
                <li><Link to={pageLinks.stake}>Stake</Link></li>
                <li><Link to={pageLinks.rewards}>Rewards</Link></li>
                {/* <li><Link to={pageLinks.referralReferee}>Referral</Link></li> */}
                <li><Link to={pageLinks.unstake}>Unstake</Link></li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="single-footer-widget">
              <h3>About</h3>
              <ul className="footer-contact-info">
                <li>
                  <Icon.MessageCircle />
                  <a href="https://discord.gg/QdkBJ33ySK" target="_blank" rel="noreferrer">
                    Integration
                  </a>
                </li>
                <li>
                  <Icon.BookOpen />
                  <a href="https://docs.defidolly.org" target="_blank" rel="noreferrer">
                    DOC
                  </a>
                </li>
                <li>
                  <Icon.Book />
                  <a href="https://docs.defidolly.org/faq" target="_blank" rel="noreferrer">
                    FAQ
                  </a>
                </li>
                <li>
                  <Icon.Mail />
                  <a href="mailto:info@defidolly.org">
                    info@defidolly.org
                  </a>
                </li>
              </ul>
              <ul className="social-links">
                <li>
                  <a
                    href={MEDIA_SETTING.discord.link}
                    target="_blank" rel="noreferrer"
                    className="facebook"
                  >
                    <RxDiscordLogo />
                  </a>
                </li>
                <li>
                  <a
                    href={MEDIA_SETTING.twitter.link}
                    target="_blank" rel="noreferrer"
                    className="twitter"
                  >
                    <Icon.Twitter />
                  </a>
                </li>
                <li>
                  <a
                    href={MEDIA_SETTING.linktree.link}
                    target="_blank" rel="noreferrer"
                    className="telegram"
                  >
                    <SiLinktree />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const style = css`
  .logo {
    margin-right: auto; 
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${styleSettingColor.text.primary};
    font-weight: bold;
    font-size: 20px;

    .header-logo {
      margin-right: 5px;
      display: inline-block;
      width: 30px;
      height: 30px;

      svg {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }
`;