import env from "env/env";

import { networkNameByNetworkID } from "blockchains/constants/blockchains";
import { EnumNetworkID, IsEnumNetworkID } from "blockchains/enums/networkIDs.enum";
import { ParseErrorContract } from "blockchains/errors/errorContract";
import { IsErrorMetaMask } from "blockchains/errors/errorMetaMask";

const getNetworkNameByNetworkID = (networkID: number): string => {
  return networkNameByNetworkID[IsEnumNetworkID(networkID) ? networkID : EnumNetworkID.Unknown];
}

const isSupportedNetworkID = (networkID: number): boolean => {
  return Object.values(env.supportedNetworkIDs).includes(networkID);
}

const getErrorMessage = (e: any, defaultMessage: string) => {
  if (IsErrorMetaMask(e)) {
    return `${defaultMessage}, [${e.code}] ${e.message}`;
  }

  let errorContract = ParseErrorContract(e);

  if (errorContract) {
    return `${defaultMessage}, [${errorContract.code}] ${errorContract.message}`;
  }

  return defaultMessage;
};

const ServiceBlockchain = {
  getNetworkNameByNetworkID,
  isSupportedNetworkID,
  getErrorMessage,
};

export default ServiceBlockchain;
