import React from "react";
import { Outlet } from "react-router-dom";
import { css } from "@emotion/css";

import usePopup from "hooks/usePopup";
import Loading from "components/Loading";
import { isDev } from "env/env";
import { useAppSelector } from "redux/hooks";
import ServiceBlockchain from "blockchains/services/blockchain.service";
import useWeb3Config from "hooks/useWeb3Config";
import ServiceFormat from "services/format.service";
import { EnumNetworkID } from "blockchains/enums/networkIDs.enum";

import Body from "DollyApp/layouts/components/Body";
import basicStyle from "DollyApp/styles/basic.style";

const DollyApp = () => {
  const popup = usePopup();
  const { handleSwitchChain } = useWeb3Config();
  const { blockchain } = useAppSelector((store) => store.web3Config);

  const handleSwitchChainMainnet = React.useCallback(() => {
    handleSwitchChain(ServiceFormat.toHexStringFromDecimal(EnumNetworkID.Mainnet));
  }, [handleSwitchChain]);

  React.useEffect(() => {
    if (blockchain.networkID === null) return;
    if (ServiceBlockchain.isSupportedNetworkID(blockchain.networkID)) return;
    popup.notice({
      className: "hint-under-header",
      message: isDev ? <>
        You're viewing data from the test network, but your wallet is connected to the <b>{ServiceBlockchain.getNetworkNameByNetworkID(blockchain.networkID)}</b>. To use DefiDolly, please switch to dolly testnet.
        {/* TODO: switch chain */}
      </> : <>
        You're viewing data from the main network, but your wallet is connected to the {ServiceBlockchain.getNetworkNameByNetworkID(blockchain.networkID)}. To use DefiDolly, please <span className="button" onClick={handleSwitchChainMainnet}>switch to {ServiceBlockchain.getNetworkNameByNetworkID(EnumNetworkID.Mainnet)}</span>
      </>,
      severity: "warning",
      duration: 99990000,
    });
  }, [popup, blockchain.networkID, handleSwitchChainMainnet]);

  return (<div className={style}>
    {/* <HeadTags
      title={METADATA_SETTING.title}
      description={METADATA_SETTING.description}
      hubURL={METADATA_SETTING.hubURL}
      image={METADATA_SETTING.image}
    /> */}
    <Body>
      <React.Suspense fallback={<Loading />}>
        <Outlet />
      </React.Suspense>
    </Body>
  </div>);
};

export default DollyApp;

const style = css`
  ${basicStyle}
`;