import { Logger } from "services/logger";
import { ServiceType } from "services/type.service";

const toString = (input: any, defaultValue?: string): string => {
  if (input === 0) {
    return "0";
  }

  return input ? input.toString() : defaultValue ? defaultValue : "";
};

const toIntegerNumber = (input: any, defaultValue?: number, radix?: number): number => {
  radix = radix ? radix : 10;
  input = parseInt(input, radix);

  if (isNaN(input)) {
    return defaultValue ? defaultValue : 0;
  } else {
    return input;
  }
};

const toNumber = (input: any, defaultValue?: number) => {
  if (typeof input !== "number") {
    input = Number(input);
  }

  if (isNaN(input)) {
    return defaultValue ? defaultValue : 0;
  } else {
    return input;
  }
};

const toBoolean = (input: any, defaultValue?: boolean): boolean => {
  if (typeof input === "string") {
    if (input === "true") {
      input = true;
    } else if (input === "false") {
      input = false;
    }
  }
  if (input === null || input === undefined) {
    return defaultValue ? defaultValue : false;
  } else {
    return input ? true : false;
  }
};

// const toChunkArray = (inputs, size) => {
//   const chunkArray = [];

//   if (Array.isArray(inputs) && inputs.length > 0) {
//     for (let i = 0; i < inputs.length; i += size) {
//       chunkArray.push(inputs.slice(i, i + size));
//     }
//   }

//   return chunkArray;
// };

// const toChunkArrayFill = (inputs, size) => {
//   const chunkArray = toChunkArray(inputs, size);

//   if (chunkArray.length <= 0) {
//     return chunkArray;
//   }

//   const chunkArrayLast = chunkArray[chunkArray.length - 1];

//   if (chunkArrayLast.length >= size) {
//     return chunkArray;
//   }

//   const newChunkArrayLast = [];

//   for (let i = 0; i < size; i++) {
//     if (chunkArrayLast[i]) {
//       newChunkArrayLast.push(chunkArrayLast[i]);
//       continue;
//     }

//     newChunkArrayLast.push(undefined);
//   }

//   const newChunkArray = chunkArray;
//   newChunkArray[chunkArray.length - 1] = newChunkArrayLast;
//   return newChunkArray;
// };

// const toURLSearchParams = (input) => {
//   let urlSearchParams = new URLSearchParams();

//   for (const k in input) {
//     if (input[k] === 0) {
//       urlSearchParams.append(k, "0");
//       continue;
//     }

//     let value = toString(input[k]);
//     urlSearchParams.append(k, encodeURIComponent(value));
//   }

//   return urlSearchParams;
// };

const toUppercaseFirstLetter = (string: string): string => {
  return string.replace(/^./, string[0].toUpperCase());
};

const toObjectArray = <T>(inputs: any, parser: (input: any) => T | undefined): Array<T> => {
  const items: Array<T> = [];

  if (!Array.isArray(inputs)) {
    Logger.warn(`Inputs must be array`, { inputs }, "ServiceFormat", "toObjectArray");
    return items;
  }

  if (inputs.length <= 0) {
    return items;
  }

  for (const input of inputs) {
    let value = parser(input);

    if (value) {
      items.push(value);
    }
  }

  return items;
};

const toHexStringFromDecimal = (input: any): string => {
  if (!ServiceType.isNumber(input)) {
    input = toIntegerNumber(input);
  }

  return `0x${input.toString(16)}`;
};

const toDecimalFromHexString = (input: any): number => {
  return parseInt(input, 16);
};

const toEtherFromWei = (input: any) => {
  if (!ServiceType.isNumber(input)) {
    input = toNumber(input);
  }

  if (isNaN(input)) return 0;

  const tokenDecimals = 1000000000000000000; // 10^18
  input = input / tokenDecimals; // unsafe

  return input;
};

const toRoundDown = (input: any, decimal: number) => {
  input = Number(input);
  if (isNaN(input)) input = 0;
  return Math.floor((input + Number.EPSILON) * Math.pow(10, decimal)) / Math.pow(10, decimal);
};

const ServiceFormat = {
  toString,
  toIntegerNumber,
  toNumber,
  toBoolean,
  toUppercaseFirstLetter,
  toHexStringFromDecimal,
  toDecimalFromHexString,
  toEtherFromWei,
  toRoundDown,
  toObjectArray,
  // toChunkArray,
  // toChunkArrayFill,
  // toURLSearchParams,
};

export default ServiceFormat;
