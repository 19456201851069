import React from "react";
import { css } from "@emotion/css/macro";
import { useAppSelector } from "redux/hooks";
import Skeleton from "@mui/material/Skeleton";

import ServiceRule from "services/rule.service";

import useAsyncGetBasicInfo from "DollyApp/hooks/useAsyncGetBasicInfo";

import chart from "../assets/Homepage/chart-eth.png";

const CurrentStake = () => {
  const { userAddress } = useAppSelector((store) => store.web3Config);

  const [getBasicInfo, basicInfoWithStatus] = useAsyncGetBasicInfo();

  React.useEffect(() => {
    getBasicInfo(userAddress);
  }, [userAddress, getBasicInfo]);

  return (
    <div className={style()}>
      <div className="repair-cta-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="cta-repair-content">
                <h3>
                  Current staking&nbsp;
                  <span>
                    APR&nbsp;
                    {basicInfoWithStatus.status.loading ? (
                      <Skeleton animation="wave" width="60px" className="loading skeleton-rounded" />
                    ) : (
                      <> {ServiceRule.formatAPR(basicInfoWithStatus.result.apr * 100)}&nbsp;%</>
                    )}
                  </span>
                </h3>
                <ul>
                  <li>Choose ETH or stETH to stake</li>
                  <li>Stake any amount</li>
                  <li>Enjoy income day and night</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="cta-repair-img">
                <img src={chart} alt="chart" width="500" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentStake;

const style = () => css`
  .repair-cta-area {
    background: linear-gradient(132.26deg, #8a2f94 0%, #f2868a 100%);
  }
  .cta-repair-content {
    h3 span {
      color: #ffd046;
      .loading {
        display: inline-flex;
        align-items: center;
      }
    }
    ul {
      padding-left: 5rem;
    }
    ul li {
      color: white;
      font-size: 1.1rem;
      text-align: left;
    }
  }

  .cta-repair-img {
    padding: 25px 0;
  }
`;
