import React from "react";

import { Logger } from "services/logger";
import { ASYNC_STATUS_ERROR, ASYNC_STATUS_LOADED, ASYNC_STATUS_LOADING, AsyncStatus } from "types/asyncStatus";
import { AsyncFunc, AsyncFuncWithResult } from "types/asyncFunc";
import ServiceFormat from "services/format.service";

import { DEFAULT_BASIC_INFO, BasicInfo } from "DollyApp/resources/dolly.factory";
import ResourceDolly from "DollyApp/resources/dolly.resource";

type Result = BasicInfo;
const defaultResult: Result = DEFAULT_BASIC_INFO;
const resourceFunc: AsyncFunc<Result> = ResourceDolly.getBasicInfo;

// ---------------------------------------------------------
export type ResultWithStatus = {
  result: Result;
  setResult: React.Dispatch<React.SetStateAction<Result>>;
  status: AsyncStatus;
  setStatus: React.Dispatch<React.SetStateAction<AsyncStatus>>;
};
type UseAsyncFunc = () => [ AsyncFunc<Result>, ResultWithStatus];

const useAsyncGetBasicInfo: UseAsyncFunc = () => {
  const [result, setResult] = React.useState<Result>(defaultResult);
  const [status, setStatus] = React.useState<AsyncStatus>(ASYNC_STATUS_LOADED);

  const asyncFunc = React.useCallback<AsyncFuncWithResult<AsyncFunc<Result>, Result>>(async (...args) => {
    let _result: Result = defaultResult;

    setStatus(ASYNC_STATUS_LOADING);
    try {
      _result = await resourceFunc(...args);
      setResult(_result);
      setStatus(ASYNC_STATUS_LOADED);
    } catch (error) {
      Logger.warn(`Unable to call "${ResourceDolly.getBasicInfo.name}"`, { args, error }, "useAsyncGetBasicInfo", "asyncFunc");
      setStatus({ ...ASYNC_STATUS_ERROR, message: ServiceFormat.toString(error) });
    }

    return _result;
  }, []);

  return [asyncFunc, { result, setResult, status, setStatus }];
};

export default useAsyncGetBasicInfo;
