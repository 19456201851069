import React from "react";

import usePopup from "hooks/usePopup";
import { css, cx } from "@emotion/css";
import { Button } from "@mui/material";
import WifiOffIcon from "@mui/icons-material/WifiOff";

import { ReactComponent as ImgError } from "assets/img-error.svg";
import { styleSettingZIndex } from "DollyApp/styles/variables.style";
import basicStyle from "DollyApp/styles/basic.style";

const offlineTitle = "No internet connection";
const onlineTitle = "Internet connection is successful !";
const OfflineHandle = () => {
  const popup = usePopup();

  const [isOffLine, setIsOffLine] = React.useState(false);

  const eventHandler = React.useCallback(
    (event: Event) => {
      const severity = event.type === "offline" ? "error" : "success";
      const message = severity === "error" ? offlineTitle : onlineTitle;

      popup.notice(({ message, severity }));

      if (event.type !== "offline") {
        setIsOffLine(event.type === "offline");
        return;
      }

      setTimeout(() => {
        setIsOffLine(event.type === "offline");
      }, 1500);
    },
    [popup]
  );

  const handleRetry = () => window.location.reload();

  React.useEffect(() => {
    setIsOffLine(!navigator.onLine);

    window.addEventListener("offline", eventHandler);
    window.addEventListener("online", eventHandler);
    return () => {
      window.removeEventListener("offline", eventHandler);
      window.removeEventListener("online", eventHandler);
    };
  }, [eventHandler]);

  if (!isOffLine) return <></>;

  return (
    <div className={cx("OfflineHandle", style)}>
      <div className="img-box">
        <ImgError />
      </div>
      <div className="message-error"><WifiOffIcon />{offlineTitle}</div>
      <Button className="dd-gradient-button" onClick={handleRetry} >Retry</Button>
    </div>
  );
};

export default OfflineHandle;

const style = css`
  ${basicStyle}

  width: 100vw;
  position: fixed;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 0;
  transform: translateX(-50%);
  background-color: #ffffffbd;
  z-index: ${styleSettingZIndex.offlineHandle};
  transition: position 2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  font-size: 20px;
  font-weight: 700;
  color: #888888;

  .MuiSvgIcon-root {
    margin-right: 15px;
    font-size: 40px;
  }
  
  .message-error {
    font-size: 20px;
    display: flex;
    align-items: center;
  }

  .img-box {
    width: 200px;
    height: 200px;

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      -webkit-filter: grayscale(75%);
      filter: grayscale(75%);
    }
  }
`;
