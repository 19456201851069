import Web3 from "web3";

import ServiceFormat from "services/format.service";
import { ErrorCreateObjectByEmpty } from "api/errors/errorCreateObjectByEmpty.class";
import { ErrorCreateObjectByColumn } from "api/errors/errorCreateObjectByColumn.class";
import { DEFAULT_ADDRESS } from "blockchains/constants/default";

// --------------------------------------------------------------------------------
// type

export type BasicInfo = {
  apr: number;
  sevenDaysAverageAPR: number;
  protocolRunningDays: number;
  days: number;
  lastUpdated: number;
};

export type UserInfo = {
  totalStake: number;
  stakeReward: number;
  referrer: string;
  isStaked: boolean;
};

export type PayoutHistoryItem = {
  date: number;
  apr: number;
};

export type PayoutHistory = Array<PayoutHistoryItem>;

export type ReferrerInfo = {
  days: number;
  sevenDaysAverageAPR: number;
};

export type ReferralRewardInfo = {
  invites: number;
  totalStaked: number;
  toBeClaimed: number;
  received: number;
  availableToClaim: number;
};

export type StakeETHResult = {
  amount: number;
};

export type StakeStETHResult = {
  amount: string;
  referrer?: string;
};

export type UnstakeResult = {
  amount: string;
};

export type ClaimReferralReward = {
  amount: string;
};

// --------------------------------------------------------------------------------
// constant

export const DEFAULT_BASIC_INFO: BasicInfo = {
  days: 0,
  sevenDaysAverageAPR: 0,
  apr: 0,
  protocolRunningDays: 0,
  lastUpdated: 0,
};

export const DEFAULT_USER_INFO: UserInfo = {
  totalStake: 0,
  stakeReward: 0,
  referrer: "",
  isStaked: false,
};

export const DEFAULT_PAYOUT_HISTORY: PayoutHistory = [];

export const DEFAULT_REFERRER_INFO: ReferrerInfo = {
  days: 0,
  sevenDaysAverageAPR: 0,
};

export const DEFAULT_REFERRAL_REWARD_INFO: ReferralRewardInfo = {
  invites: 0,
  totalStaked: 0,
  toBeClaimed: 0,
  received: 0,
  availableToClaim: 0,
};

export const DEFAULT_STAKE_ETH_RESULT: StakeETHResult = {
  amount: 0,
};

export const DEFAULT_STAKE_STETH_RESULT: StakeStETHResult = {
  amount: "",
  referrer: "",
};

export const DEFAULT_UNSTAKE_RESULT: UnstakeResult = {
  amount: "",
};

export const DEFAULT_CLAIM_REFERRAL_REWARD_RESULT: ClaimReferralReward = {
  amount: "",
};

// --------------------------------------------------------------------------------
// factory

const createBasicInfoFromAPI = (apiResponse: any): BasicInfo => {
  const objectName = "BasicInfo";

  if (!apiResponse) {
    throw new ErrorCreateObjectByEmpty(objectName);
  }

  return {
    apr: ServiceFormat.toNumber(apiResponse["APR"]),
    sevenDaysAverageAPR: ServiceFormat.toNumber(apiResponse["7daysAverageAPR"]),
    protocolRunningDays: ServiceFormat.toNumber(apiResponse["protocolRunningDays"]),
    days: ServiceFormat.toNumber(apiResponse["protocolRunningDays"]), // days === protocolRunningDays
    lastUpdated: ServiceFormat.toNumber(apiResponse["lastUpdated"]),
  };
};

const createUserInfoFromAPI = (apiResponse: any): UserInfo => {
  const objectName = "UserInfo";

  if (!apiResponse) {
    throw new ErrorCreateObjectByEmpty(objectName);
  }

  const referrer = apiResponse["referrer"] !== DEFAULT_ADDRESS ? apiResponse["referrer"] : "";

  return {
    totalStake: ServiceFormat.toNumber(apiResponse["totalStake"]),
    stakeReward: ServiceFormat.toNumber(apiResponse["stakeReward"]),
    referrer: ServiceFormat.toString(referrer),
    isStaked: ServiceFormat.toBoolean(apiResponse["isStaked"]),
  };
};

const _createPayoutHistoryItemFromAPI = (apiResponse: any): PayoutHistoryItem => {
  const objectName = "PayoutHistoryItem";

  if (!apiResponse) {
    throw new ErrorCreateObjectByEmpty(objectName);
  }

  return {
    date: ServiceFormat.toNumber(apiResponse["date"]),
    apr: ServiceFormat.toNumber(apiResponse["APR"]),
  };
};

const createPayoutHistoryFromAPI = (apiResponses: any): PayoutHistory => {
  let list: PayoutHistory = [];

  if (!Array.isArray(apiResponses)) {
    return list;
  }

  if (apiResponses.length <= 0) {
    return list;
  }

  list = apiResponses.map((apiResponse) =>
    _createPayoutHistoryItemFromAPI(apiResponse)
  );

  return list;
};

const createReferrerInfoFromAPI = (apiResponse: any): ReferrerInfo => {
  const objectName = "ReferrerInfo";

  if (!apiResponse) {
    throw new ErrorCreateObjectByEmpty(objectName);
  }

  return {
    days: ServiceFormat.toNumber(apiResponse["days"]),
    sevenDaysAverageAPR: ServiceFormat.toNumber(apiResponse["7daysAverageAPR"]),
  };
};

const createReferralRewardInfoFromAPI = (apiResponse: any): ReferralRewardInfo => {
  const objectName = "ReferralRewardInfo";

  if (!apiResponse) {
    throw new ErrorCreateObjectByEmpty(objectName);
  }

  return {
    invites: ServiceFormat.toNumber(apiResponse["invites"]),
    totalStaked: ServiceFormat.toNumber(apiResponse["totalStaked"]),
    toBeClaimed: ServiceFormat.toNumber(apiResponse["toBeClaimed"]),
    received: ServiceFormat.toNumber(apiResponse["received"]),
    availableToClaim: ServiceFormat.toNumber(apiResponse["availableToClaim"]),
  };
};

const createStakeETHResultFromAPI = (abiResponse: any): StakeETHResult => {
  const objectName = "StakeETHResult";

  if (!abiResponse) {
    throw new ErrorCreateObjectByEmpty(objectName);
  }

  if (
    !abiResponse.events ||
    !abiResponse.events["Stake"] ||
    !abiResponse.events["Stake"].returnValues ||
    !abiResponse.events["Stake"].returnValues["amount"]
  ) {
    throw new ErrorCreateObjectByColumn(objectName, "events");
  }

  const amountByWei = abiResponse.events["Stake"].returnValues["amount"];
  const amountByEther = Web3.utils.fromWei(amountByWei, "ether");

  return {
    amount: ServiceFormat.toNumber(amountByEther),
  };
};

const createStakeStETHResultFromAPI = (abiResponse: any): StakeStETHResult => {
  const objectName = "StakeStETHResult";

  if (!abiResponse) {
    throw new ErrorCreateObjectByEmpty(objectName);
  }

  if (
    !abiResponse.events ||
    !abiResponse.events["Stake"] ||
    !abiResponse.events["Stake"].returnValues ||
    !abiResponse.events["Stake"].returnValues["amount"]
  ) {
    throw new ErrorCreateObjectByColumn(objectName, "events");
  }

  const amountByWei = abiResponse.events["Stake"].returnValues["amount"];
  const amountByEther = Web3.utils.fromWei(amountByWei, "ether");

  return {
    amount: ServiceFormat.toString(amountByEther),
    referrer: ServiceFormat.toString(""),
  };
};

const createUnstakeResultFromAPI = (abiResponse: any): UnstakeResult => {
  const objectName = "UnstakeResult";
  if (!abiResponse) {
    throw new ErrorCreateObjectByEmpty(objectName);
  }

  if (
    !abiResponse.events ||
    !abiResponse.events["Unstake"] ||
    !abiResponse.events["Unstake"].returnValues ||
    !abiResponse.events["Unstake"].returnValues["unstakedAmount"]
  ) {
    throw new ErrorCreateObjectByColumn(objectName, "events");
  }

  const amountByWei =
    abiResponse.events["Unstake"].returnValues["unstakedAmount"];
  const amountByEther = Web3.utils.fromWei(amountByWei, "ether");

  return {
    amount: ServiceFormat.toString(amountByEther),
  };
};

const createClaimReferralRewardFromAPI = (abiResponse: any): ClaimReferralReward => {
  const objectName = "ClaimReferralReward";

  if (!abiResponse) {
    throw new ErrorCreateObjectByEmpty(objectName);
  }

  if (
    !abiResponse.events ||
    !abiResponse.events["ClaimReferralReward"] ||
    !abiResponse.events["ClaimReferralReward"].returnValues ||
    !abiResponse.events["ClaimReferralReward"].returnValues["claimAmount"]
  ) {
    throw new ErrorCreateObjectByColumn(objectName, "events");
  }

  const amountByWei = abiResponse.events["ClaimReferralReward"].returnValues["claimAmount"];
  const amountByEther = Web3.utils.fromWei(amountByWei, "ether");

  return {
    amount: ServiceFormat.toString(amountByEther),
  };
};

const FactoryDolly = {
  createBasicInfoFromAPI,
  createUserInfoFromAPI,
  createPayoutHistoryFromAPI,
  createReferrerInfoFromAPI,
  createReferralRewardInfoFromAPI,
  createStakeETHResultFromAPI,
  createStakeStETHResultFromAPI,
  createUnstakeResultFromAPI,
  createClaimReferralRewardFromAPI
};

export default FactoryDolly;
