import React from "react";
import { Link } from "react-router-dom";
import { css } from "@emotion/css/macro";

import twitter from "../public/images/contact/Twitter.png";
import linktree from "../public/images/contact/linktree.png";
import discord from "../public/images/contact/Discord.png";
import info from "../public/images/contact/info.png";
import { MEDIA_SETTING } from "DollyApp/constants";

const IndustriesWeServe = () => {
  return (
    <div className={style()}>
      <div className="hosting-features-area ptb-80 bg-f9f6f6">
        <div className="container">
          <div className="section-title">
            <h2>Join DefiDolly <span>community</span></h2>
            <p>We Always Try To Understand Users Expectation.</p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-6 col-sm-6 col-md-4">
              <div className="single-industries-serve-box">
                <Link to={MEDIA_SETTING.twitter.link} className="header-link">
                  <div className="icon">
                    <img src={twitter} alt='twitter' width='40' />
                  </div>
                  Twitter
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-6 col-sm-6 col-md-4">
              <div className="single-industries-serve-box">
                <Link to={MEDIA_SETTING.linktree.link} className="header-link">
                  <div className="icon">
                    <img src={linktree} alt='telegram' width='40' />
                  </div>
                  Linktree
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-6 col-sm-6 col-md-4">
              <div className="single-industries-serve-box">
                <Link to={MEDIA_SETTING.discord.link} className="header-link">
                  <div className="icon">
                    <img src={discord} alt='discord' width='40' />
                  </div>
                  Discord
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-6 col-sm-6 col-md-4">
              <div className="single-industries-serve-box"
                style={{ fontSize: "15px" }}>
                <div className="icon">
                  <img src={info} alt='info' width='40' />
                </div>
                info@defidolly.org
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustriesWeServe;

const style = () => css`
.header-link{
    display: block;
    &:hover{
        color: white;
    }
}
.section-title{
    h2{
        color: #10304C;
        padding: 1.5rem 0;
        span{
            color:#DC748D;
        }
    }
    p{
        color: #10304C;
        font-weight: 500;
    }
}
.single-industries-serve-box{
    .icon{
        display: flex;
    } 
}

@media screen and (max-width:768px) {
    .single-industries-serve-box{
    .icon{
        display: flex;
        justify-content: center;
    } 
}
}
`;