import React from 'react';
import { css } from "@emotion/css/macro";

import manAndWomen from '../public/images/man-and-women.png';
import circle from '../public/images/circle.png';
import ctaShape from '../public/images/cta-shape.png';


const CTA = () => {
  return (
    <div className={style}>
      <div className="repair-cta-area bg-0f054b">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="cta-repair-content">
                <h3>5% referral rewards every day</h3>
                <ul>
                  <li>Share Referral Link to friends.</li>
                  <li>Anyone stakes through your link.</li>
                  <li>You get 5% Referral rewards <span>every day.</span></li>
                </ul>
                <h5>Are you a bellwether ?</h5>
                <div className="btn btn-primary" >
                  <a href="https://docs.defidolly.org/earn-more-referral-program" target="_blank" rel="noreferrer" className="header-link">
                    Check Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="cta-repair-img">
                <img src={manAndWomen} alt="manAndWomen" />
              </div>
            </div>
          </div>
        </div>
        <div className="circle-box">
          <img src={circle} alt="circle" />
        </div>
        <div className="cta-shape">
          <img src={ctaShape} alt="ctaShape" />
        </div>
      </div>
    </div>
  );
};

export default CTA;

const style = css`
.cta-repair-content{
    h3 span{
        color: #FFD046;
    }
    ul{
        padding-left: 3.5rem;
    }
    ul li {
        list-style: "";
        color: white;
        font-size: 1.1rem;
        text-align: left;
        span{
            color: #DC748D;
        }
        &:before {
            content: '✓ ';
        }
    }
    h5{
        padding-top: 4rem;
        color: white;
    }
}

.header-link{
    color: white;
}
`;