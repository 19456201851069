import { css } from "@emotion/css/macro";

import stack from "../assets/Homepage/Stake.png";
import leverage from "../assets/Homepage/Leverage.png";
import rewards from "../assets/Homepage/rewards.png";

const Features = () => {
  return (
    <div className={style}>
      <div className="hosting-features-area pt-80 pb-50 bg-f9f6f6">
        <div className="container">
          <div className="section-title">
            <h2>How DefiDolly <span>works?</span></h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-hosting-features">
                <div className="icon-block">
                  <img src={stack} alt="stack" />
                </div>
                <h3>step1 : Stake</h3>
                <p>Stake any amount of your ETH or stETH.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-hosting-features">
                <div className="icon-block">
                  <img src={leverage} alt="stack" />
                </div>
                <h3>step2 : Leverage</h3>
                <p>The return is maximized by increasing exposure to stETH by borrowing ETH in Compound v3.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-hosting-features">
                <div className="icon-block">
                  <img src={rewards} alt="stack" />
                </div>
                <h3>step3 : Get Boost rewards</h3>
                <p>DefiDolly helps you to get up to 10x staking rewards.
                  Earnings come from ETH POS provided by Lido.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Features;

const style = css`
  .section-title {
    span {
      color: #DC748D;
    }
  }
  .single-hosting-features {
    height: 18rem;

    .icon-block {
      text-align: center;
      margin: 0 auto;
      width: 55px;
      height: 55px;
      line-height: 55px;
    }
  }
`;