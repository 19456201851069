import React from "react";
import { css, cx } from "@emotion/css";

import useScrollHandler from "hooks/useScrollHandler";

import Header from "DollyApp/layouts/components/Header";
import Footer from "DollyApp/layouts/components/Footer";
import useInnerHeight from "hooks/useInnerHeight";
import { styleSettingHeight } from "DollyApp/styles/variables.style";

type Props = {
  children?: React.ReactNode;
  mainClassName?: string;
  layoutClassName?: string;
};

const LayoutHome = (props: Props) => {
  useScrollHandler();
  const [innerHeight] = useInnerHeight();

  return (
    <div className={cx("DD-LayoutHome", style(innerHeight), props.layoutClassName)}>
      <Header />
      <main id="websiteTop" className={props.mainClassName}>
        {props.children}
      </main>
      <Footer />
    </div>
  );
};

export default LayoutHome;

const style = (_innerHeight: number) => css`
  main {
    min-height: calc(${_innerHeight}px - ${styleSettingHeight.header});
    width: 100%;
    background-color: #eee;
  }
`;