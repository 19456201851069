
export enum EnumTypeConnectChain {
  CONNECT_CHAIN_LOADED = "CONNECT_CHAIN_LOADED",
  CONNECT_CHAIN_ERROR = "CONNECT_CHAIN_ERROR"
}

export enum EnumTypeGetChain {
  GET_CHAIN_LOADING = "GET_CHAIN_LOADING",
  GET_CHAIN_LOADED = "GET_CHAIN_LOADED",
  GET_CHAIN_ERROR = "GET_CHAIN_ERROR",
  GET_CHAIN_RESET = "GET_CHAIN_RESET", // use yet
}

export enum EnumTypeAccount {
  ACCOUNT_LOADING = "ACCOUNT_LOADING",
  ACCOUNT_LOADED = "ACCOUNT_LOADED",
  ACCOUNT_ERROR = "ACCOUNT_ERROR",
  ACCOUNT_RESET = "ACCOUNT_RESET", // use yet
}

export enum EnumTypeConnectWallet {
  CONNECT_WALLET_LOADING = "CONNECT_WALLET_LOADING",
  CONNECT_WALLET_LOADED = "CONNECT_WALLET_LOADED",
  CONNECT_WALLET_ERROR = "CONNECT_WALLET_ERROR",
  CONNECT_WALLET_RESET = "CONNECT_WALLET_RESET", // use yet
}
// Distinguish between A and B, avoid to avoid errors when unconnected

export enum EnumTypeBalance {
  BALANCE_LOADING = "BALANCE_LOADING",
  BALANCE_LOADED = "BALANCE_LOADED",
  BALANCE_ERROR = "BALANCE_ERROR",
  BALANCE_RESET = "BALANCE_RESET",
}

export enum EnumTypeMessage {
  MESSAGE_LOADED = "MESSAGE_LOADfED"
}

export enum EnumTypeWeb3 {
  WEB3_RESET = "WEB3_RESET" // use yet
}