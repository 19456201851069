import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import env from "env/env";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: env.originAPI,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  config.params = { ...config.params };
  return config;
});

axiosInstance.interceptors.response.use((response) => {
  // From consensus with the backend
  if (!response["data"]["success"]) throw new Error(response["data"]["msg"]);
  return response["data"];
});

const ServiceAPI = {
  delete: (url: string, config?: AxiosRequestConfig) => axiosInstance.delete(url, config),
  get: (url: string, config?: AxiosRequestConfig) => axiosInstance.get(url, config),
  post: (url: string, data?: any, config?: AxiosRequestConfig) => axiosInstance.post(url, data, config),
  put: (url: string, data?: any, config?: AxiosRequestConfig) => axiosInstance.put(url, data, config),
};

export default ServiceAPI;
