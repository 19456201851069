import React from "react";

import { SPECIAL_REGULATION } from "DollyApp/constants";

import env from "env/env";
import usePopup from "hooks/usePopup";
import ServiceFormat from "services/format.service";
import { Logger } from "services/logger";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  EnumTypeAccount,
  EnumTypeBalance,
  EnumTypeConnectChain,
  EnumTypeConnectWallet,
  EnumTypeGetChain,
  EnumTypeMessage,
  EnumTypeWeb3,
} from "redux/enums/web3Config";

import {
  OnConnect,
  OnDisconnect,
  ProviderRpcError,
  ProviderMessage,
} from "blockchains/types/web3Config.types";
import { chainInfoByNetworkID } from "blockchains/constants/blockchains";
import { EnumNetworkID } from "blockchains/enums/networkIDs.enum";
import ServiceBlockchain from "blockchains/services/blockchain.service";
import contractInfo from "blockchains/constants/contractInfo";

type Web3Methods = {
  detectProvider: () => boolean;
  detectProviderIsConnectedChain: () => boolean;
  detectIsSupportedChain: () => boolean;
  detectChain: () => boolean;
  handleSwitchChain: (chainID?: string) => void;
  addTokenWETH: () => void;
  handleAddChain: (chainID?: string) => void;
  handleConnectWallet: () => void;
  handleGetBalance: (_userAddress: string) => void;
};

const useWeb3Config = (isInit?: boolean): Web3Methods => {
  const { blockchain, userAddress } = useAppSelector((store) => store.web3Config);
  const dispatch = useAppDispatch();
  const popup = usePopup();

  const detectProvider = React.useCallback(() => {
    if ((window as any).ethereum) return true;

    Logger.warn(
      `Provider does not exist`,
      undefined,
      "useWeb3Config",
      "detectProvider"
    );
    // popup.notice({
    //   message: (
    //     <>
    //       Provider does not exist, please{" "}
    //       <a style={{ color: "#ffffff", fontWeight: "bold" }} href={ServicePlatform.getMetamaskAppLink()}>
    //         install MetaMask.
    //       </a>
    //     </>
    //   ),
    //   severity: "error",
    //   duration: 99990000,
    // });

    return false;
  }, []);

  const detectProviderIsConnectedChain = React.useCallback(() => {
    if (!(window as any).ethereum) return;
    if (!(window as any).ethereum.isConnected) return;
    if ((window as any).ethereum && !(window as any).ethereum.isConnected()) {
      Logger.warn(
        `Provider is not connected to blockchain`,
        undefined,
        "useWeb3Config",
        "detectProviderIsConnectedChain"
      );
      popup.notice({
        message:
          "Provider is not connected to blockchain, please check your internet connection status.",
        severity: "error",
        duration: 99990000,
      });
    }
    return (window as any).ethereum.isConnected();
  }, [popup]);

  const detectIsSupportedChain = React.useCallback(() => {
    if (!blockchain.isSupported) {
      Logger.warn(
        `Unsupported network, the supported networkID is ${env.supportedNetworkIDs}, please switch chain`,
        undefined,
        "useWeb3Config",
        "detectIsSupportedChain"
      );
      popup.notice({
        message: `Unsupported network, the supported networkID is ${env.supportedNetworkIDs}, please switch chain`,
        severity: "error",
        duration: 99990000,
      });
    }

    return blockchain.isSupported;
  }, [popup, blockchain.isSupported]);

  const detectChain = React.useCallback(() => {
    return blockchain.isConnected && blockchain.isSupported;
  }, [blockchain.isConnected, blockchain.isSupported]);

  const handleAddChain = React.useCallback(
    (_chainID?: string) => {
      if (!detectProvider() || !detectProviderIsConnectedChain()) return;

      if (!_chainID)
        _chainID =
          chainInfoByNetworkID[env.supportedNetworkIDs[0]].chainId;

      dispatch({ type: EnumTypeGetChain.GET_CHAIN_LOADING });

      const networkID = ServiceFormat.toDecimalFromHexString(_chainID);

      (window as any).ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: chainInfoByNetworkID[networkID],
        })
        .then(() => {
          popup.notice({
            message: `Successfully added ${ServiceBlockchain.getNetworkNameByNetworkID(
              networkID
            )}!`,
          });
        })
        .catch((error: ProviderRpcError) => {
          Logger.warn(
            "Unable to add chain",
            { error },
            "useWeb3Config",
            "handleAddChain"
          );
          dispatch({
            type: EnumTypeGetChain.GET_CHAIN_ERROR,
            payload: `Unsupported network, the supported networkID is ${ServiceBlockchain.getNetworkNameByNetworkID(
              networkID
            )}, please add chain`,
          });
        });
      /* Do not handle success case (update chain & stop async status loading).
       Because success case of Promise "wallet_switchEthereumChain" return null,
       and chain listener will handle success case. */
    },
    [popup, dispatch, detectProvider, detectProviderIsConnectedChain]
  );

  const handleSwitchChain = React.useCallback(
    (_chainID?: string) => {
      if (!detectProvider() || !detectProviderIsConnectedChain()) return;

      if (!_chainID)
        _chainID =
          chainInfoByNetworkID[env.supportedNetworkIDs[0]].chainId;

      dispatch({ type: EnumTypeGetChain.GET_CHAIN_LOADING });
      (window as any).ethereum
        .request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: _chainID }],
        })
        .then(() => {
          popup.notice({
            message: `Successfully switched to ${chainInfoByNetworkID[env.supportedNetworkIDs[0]].chainName
              }!`,
          });
        })
        .catch((error: ProviderRpcError) => {
          Logger.warn(
            "Unable to switch chain",
            { error },
            "useWeb3Config",
            "handleSwitchChain"
          );
          dispatch({
            type: EnumTypeGetChain.GET_CHAIN_ERROR,
            payload: `Unsupported network, the supported networkID is ${env.supportedNetworkIDs[0]}, please switch chain`,
          });
          // the chain has not been added to MetaMask
          if (error.code === 4902) {
            const targetNetworkID = env
              .supportedNetworkIDs[0] as EnumNetworkID;
            const targetChainID = chainInfoByNetworkID[targetNetworkID].chainId;
            handleAddChain(targetChainID);
            return;
          }
        });
      /* Do not handle success case (update chain & stop async status loading).
         Because success case of Promise "wallet_switchEthereumChain" return null,
         and chain listener will handle success case.  */
    },
    [
      popup,
      dispatch,
      detectProvider,
      detectProviderIsConnectedChain,
      handleAddChain,
    ]
  );

  const addTokenWETH = React.useCallback(() => {
    if (!contractInfo.wETHContractAddress) return;
    if (!detectProvider() || !detectProviderIsConnectedChain()) return;

    //dispatch({ type: EnumTypeGetChain.GET_CHAIN_LOADING });
    (window as any).ethereum
      .request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: contractInfo.wETHContractAddress,
            symbol: "WETH",
            decimals: 18,
          },
        },
      })
      .then(() => {
        popup.notice({
          message: "Successfully add wETH to MetaMask!",
        });
      })
      .catch((error: ProviderRpcError) => {
        Logger.warn(
          "Unable to add wETH token",
          { error },
          "useWeb3Config",
          "addTokenWETH"
        );
        //dispatch({
        //  type: EnumTypeGetChain.GET_CHAIN_ERROR,
        //  payload: `Unsupported network, the supported networkID is ${env.supportedNetworkIDs[0]}, please switch chain`,
        //});
      });
  }, [popup, detectProvider, detectProviderIsConnectedChain]);

  const _handleChainChanged = React.useCallback(
    (chainID: string) => {
      const networkID = ServiceFormat.toDecimalFromHexString(chainID);
      const networkName =
        ServiceBlockchain.getNetworkNameByNetworkID(networkID);
      const isSupported = ServiceBlockchain.isSupportedNetworkID(networkID);
      const _blockchain = { chainID, networkID, networkName, isSupported };

      dispatch({
        type: EnumTypeGetChain.GET_CHAIN_LOADED,
        payload: _blockchain,
      });
      // window.location.reload()
    },
    [dispatch]
  );

  const _handleProviderConnectBlockchainChanged: OnConnect = React.useCallback(
    (_connectInfo) => {
      Logger.info(
        "connectInfo:",
        _connectInfo,
        "useWeb3Config",
        "_handleProviderConnectBlockchainChanged"
      );
      dispatch({
        type: EnumTypeConnectChain.CONNECT_CHAIN_LOADED,
        payload: {
          chainID: _connectInfo.chainId,
          isConnected: (window as any).ethereum
            ? (window as any).ethereum.isConnected === undefined
              ? true
              : (window as any).ethereum.isConnected()
            : false,
        },
      });
    },
    [dispatch]
  );

  const _handleProviderDisconnectBlockchainChanged: OnDisconnect =
    React.useCallback(
      (error) => {
        Logger.warn(
          `Ethereum Provider connection closed, Code: ${error.message}`,
          error,
          "useWeb3Config",
          "_handleProviderDisconnectBlockchainChanged"
        );
        dispatch({
          type: EnumTypeConnectChain.CONNECT_CHAIN_ERROR,
          payload: `Ethereum Provider connection closed, Code: ${error.message}`,
        });
      },
      [dispatch]
    );

  const _handleAccountsChanged = React.useCallback(
    (_accounts: string[]) => {
      dispatch({ type: EnumTypeAccount.ACCOUNT_LOADED, payload: _accounts[0] });
      dispatch({
        type: EnumTypeConnectWallet.CONNECT_WALLET_LOADED,
        payload: _accounts[0],
      });
    },
    [dispatch]
  );

  const handleConnectWallet = React.useCallback(() => {
    if (!detectProvider()) return;

    dispatch({ type: EnumTypeAccount.ACCOUNT_LOADING });
    dispatch({ type: EnumTypeConnectWallet.CONNECT_WALLET_LOADING });
    (window as any).ethereum
      .request({ method: "eth_requestAccounts" })
      .then(_handleAccountsChanged)
      .then(() => {
        popup.notice({ message: "Connect success!" });
      })
      .catch((error: ProviderRpcError) => {
        Logger.warn(
          "Unable to connect wallet",
          { error },
          "useWeb3Config",
          "handleConnectWallet"
        );
        let errorMessage = `Unable to connect wallet: ${error.message}`;
        if (error.code === 4001) {
          errorMessage = `Error Connect Wallet: ${error.message}. Please connect to MetaMask.`;
        }
        dispatch({
          type: EnumTypeConnectWallet.CONNECT_WALLET_ERROR,
          payload: errorMessage,
        });
      });
  }, [dispatch, detectProvider, _handleAccountsChanged, popup]);

  const _handleBalanceChanged = React.useCallback(
    (_balance: number) => {
      // The unit of balance is wei
      const balanceByEther = ServiceFormat.toEtherFromWei(_balance);
      dispatch({
        type: EnumTypeBalance.BALANCE_LOADED,
        payload: ServiceFormat.toRoundDown(
          balanceByEther,
          SPECIAL_REGULATION.decimalPlaceOfAmount
        ),
      });
      return ServiceFormat.toRoundDown(
        balanceByEther,
        SPECIAL_REGULATION.decimalPlaceOfAmount
      );
    },
    [dispatch]
  );

  const handleGetBalance = React.useCallback(
    (_userAddress: string) => {
      if (
        !(window as any).ethereum ||
        !blockchain.isConnected ||
        !blockchain.isSupported ||
        !_userAddress
      )
        return;
      dispatch({ type: EnumTypeBalance.BALANCE_LOADING });
      return (window as any).ethereum
        .request({
          method: "eth_getBalance",
          params: [_userAddress, "latest"],
        })
        .then(_handleBalanceChanged)
        .catch((error: ProviderRpcError) => {
          Logger.warn(
            `Unable to get balance`,
            { error },
            "useWeb3Config",
            "handleGetBalance"
          );
          dispatch({
            type: EnumTypeBalance.BALANCE_ERROR,
            payload: `Unable to get balance, ${error.message}`,
          });
        });
    },
    [
      dispatch,
      _handleBalanceChanged,
      blockchain.isConnected,
      blockchain.isSupported,
    ]
  );

  const _handleMessageChanged = React.useCallback(
    (_message: ProviderMessage) => {
      dispatch({ type: EnumTypeMessage.MESSAGE_LOADED, payload: _message });
    },
    [dispatch]
  );

  // init Web3
  React.useEffect(() => {
    if (!isInit) return;

    const initWeb3 = async () => {
      // --------------------------------------------------------
      // detect provider
      if (!detectProvider()) {
        dispatch({ type: EnumTypeWeb3.WEB3_RESET });
        return;
      }
      // --------------------------------------------------------
      // detect window.ethereum.isConnected()
      dispatch({
        type: EnumTypeConnectChain.CONNECT_CHAIN_LOADED,
        payload: {
          isConnected: (window as any).ethereum
            ? (window as any).ethereum.isConnected === undefined
              ? true
              : (window as any).ethereum.isConnected()
            : false,
        },
      });

      // listen provider is connect blockchain
      (window as any).ethereum.on(
        "connect",
        _handleProviderConnectBlockchainChanged
      );
      (window as any).ethereum.on(
        "disconnect",
        _handleProviderDisconnectBlockchainChanged
      );

      // --------------------------------------------------------
      // detect blockchain
      dispatch({ type: EnumTypeGetChain.GET_CHAIN_LOADING });
      (window as any).ethereum
        .request({ method: "eth_chainId" })
        .then(_handleChainChanged)
        .catch((error: ProviderRpcError) => {
          Logger.warn(
            "Unable to get chainID",
            { error },
            "useWeb3Config",
            "loadWeb3"
          );
          dispatch({
            type: EnumTypeGetChain.GET_CHAIN_ERROR,
            payload: `Unable to get chainID: ${error.message}`,
          });
        });

      // listen chain change
      (window as any).ethereum.on("chainChanged", _handleChainChanged);

      // --------------------------------------------------------
      // detect if connected wallet
      // detect if available account exist
      dispatch({ type: EnumTypeAccount.ACCOUNT_LOADING });
      dispatch({ type: EnumTypeConnectWallet.CONNECT_WALLET_LOADING });

      (window as any).ethereum
        .request({ method: "eth_accounts" })
        .then(_handleAccountsChanged)
        .catch((error: ProviderRpcError) => {
          Logger.warn(
            "Unable to get accounts",
            { error },
            "useWeb3Config",
            "loadWeb3"
          );
          dispatch({
            type: EnumTypeAccount.ACCOUNT_ERROR,
            payload: `Unable to get accounts: ${error.message}`,
          });
        });

      // listen accounts change
      (window as any).ethereum.on("accountsChanged", _handleAccountsChanged);
      // --------------------------------------------------------
      // listen message event
      (window as any).ethereum.on("message", _handleMessageChanged);
      // --------------------------------------------------------
    };
    initWeb3();

    return () => {
      if (!(window as any).ethereum) return;
      (window as any).ethereum.isConnected &&
        (window as any).ethereum.removeListener(
          "connect",
          _handleProviderConnectBlockchainChanged
        );
      (window as any).ethereum.isConnected &&
        (window as any).ethereum.removeListener(
          "disconnect",
          _handleProviderDisconnectBlockchainChanged
        );
      (window as any).ethereum.removeListener(
        "chainChanged",
        _handleChainChanged
      );
      (window as any).ethereum.removeListener(
        "accountsChanged",
        _handleAccountsChanged
      );
      (window as any).ethereum.removeListener("message", _handleMessageChanged);
    };
  }, [
    isInit,
    dispatch,
    detectProvider,
    _handleProviderConnectBlockchainChanged,
    _handleProviderDisconnectBlockchainChanged,
    _handleChainChanged,
    _handleAccountsChanged,
    _handleMessageChanged,
  ]);

  // handle Get Balance
  React.useEffect(() => {
    if (!isInit) return;

    // detect require condition
    if (
      !(window as any).ethereum ||
      !blockchain.isConnected ||
      !blockchain.isSupported ||
      !userAddress
    )
      return;
    handleGetBalance(userAddress);
    // [Get Balance Timing]
    // - when provider not exist
    // - when blockchain.chainID update
    // - when userAddress update
    // - when sending transaction and change Balance
  }, [
    isInit,
    handleGetBalance,
    blockchain.isConnected,
    blockchain.isSupported,
    blockchain.chainID,
    userAddress,
  ]);

  return {
    detectProvider,
    detectProviderIsConnectedChain,
    detectIsSupportedChain,
    detectChain,
    handleSwitchChain,
    addTokenWETH,
    handleAddChain,
    handleConnectWallet,
    handleGetBalance,
  };
};

export default useWeb3Config;
