
export const styleSettingHeight = {
  header: "48px",
  footer: "472px"
};

export const styleSettingZIndex = {
  header: 1501,
  sidebar: 1500,
  offlineHandle: 9998,
  popup: 9999,
};

export const styleSettingColor = {
  primary: "#C25F8F",
  secondary: "#1E9893",
  light: "rgba(89, 89, 89, 0.1)",
  // dark: "#030D49",
  success: "#1E9893",
  warning: "#F4BA10",
  danger: "#FE0303",
  info: "#030D49",
  text: {
    primary: "#211837",
    secondary: "#595959",
  },
  bg: "#EFEFEF",
  disabled: "#D4D4D4",
}

export const breakpoints = {
  sm: "420px",
  md: "768px",
}