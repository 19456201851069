import ServiceFormat from "services/format.service";
import ServiceUtil from "services/util.service";

const AMOUNT_ROUND_TO_DECIMAL_PLACE = 6;
const APR_ROUND_TO_DECIMAL_PLACE = 2;
const ESTIMATE_GAS_FEE_ROUND_TO_DECIMAL_PLACE = 6;
export const LOCAL_STORAGE_KEY_REFERRER = "DefiDolly-Referrer";

const formatAmount = (number: number): number =>  ServiceUtil.roundDownDecimals(number, AMOUNT_ROUND_TO_DECIMAL_PLACE);
const formatAPR = (number: number): number => ServiceUtil.roundDownDecimals(number, APR_ROUND_TO_DECIMAL_PLACE);

const formatAmountString = (number: number):string => {
  if(ServiceUtil.roundDownDecimals(number, AMOUNT_ROUND_TO_DECIMAL_PLACE) === 0 && number > 0) {
    const convertMetricNotationToString = ServiceUtil.convertMetricNotationToString(ServiceFormat.toString(number));
    return convertMetricNotationToString;
  }
  
  return ServiceFormat.toString(ServiceUtil.roundDownDecimals(number, AMOUNT_ROUND_TO_DECIMAL_PLACE));
};


const formatEstimateGasFee = (number: number) => {
  const estimateGasFeeByRoundDown = ServiceUtil.roundDownDecimals(number, ESTIMATE_GAS_FEE_ROUND_TO_DECIMAL_PLACE);
  if(estimateGasFeeByRoundDown > 0 ) return estimateGasFeeByRoundDown;

  let estimateGasFeeNotByRoundDown = ServiceUtil.convertMetricNotationToString(ServiceFormat.toString(number));
  
  if(estimateGasFeeNotByRoundDown.length > 20) return estimateGasFeeNotByRoundDown.slice(0,19)
  
  return estimateGasFeeNotByRoundDown;
};

const ServiceRule = {
  formatAmount,
  formatAPR,
  formatAmountString,
  formatEstimateGasFee
};

export default ServiceRule;
