import React from "react";
import { Navigate , RouteObject } from "react-router-dom";

import { PAGE_LINKS_DOLLY_APP } from "DollyApp/constants";
import routesReferral from "DollyApp/modules/referral/routes";
import Home from "DollyApp/pages/Home/Home";

// const Term = React.lazy(() => import(/* webpackChunkName: "Page.Term" */"DollyApp/pages/Term/Term"));
// const Policy = React.lazy(() => import(/* webpackChunkName: "Page.Policy" */"DollyApp/pages/Policy/Policy"));
const Stake = React.lazy(() => import(/* webpackChunkName: "Page.Stake" */"DollyApp/modules/stakable/pages/Stake"));
const Unstake = React.lazy(() => import(/* webpackChunkName: "Page.Unstake" */"DollyApp/modules/stakable/pages/Unstake"));
const Rewards = React.lazy(() => import(/* webpackChunkName: "Page.Rewards" */"DollyApp/modules/rewards/pages/Rewards"));

const routesDollyApp: Array<RouteObject> = [
  {
    index: true,
    element: <Navigate to={PAGE_LINKS_DOLLY_APP.stake} replace />
  },
  {
    path: PAGE_LINKS_DOLLY_APP.home,
    element: <Home />
  },
  // {
  //   path: PAGE_LINKS_DOLLY_APP.policy,
  //   element: <Policy />
  // },
  // {
  //   path: PAGE_LINKS_DOLLY_APP.term,
  //   element: <Term />
  // },
  {
    path: PAGE_LINKS_DOLLY_APP.stake,
    element: <Stake />
  },
  {
    path: PAGE_LINKS_DOLLY_APP.unstake,
    element: <Unstake />
  },
  {
    path: PAGE_LINKS_DOLLY_APP.rewards,
    element: <Rewards />
  },
  ...routesReferral
];

export default routesDollyApp;

export const pageLinks = Object.fromEntries(
  Object.entries(PAGE_LINKS_DOLLY_APP).map(([key, value]) => [key, "/" + value])
) as typeof PAGE_LINKS_DOLLY_APP;