import React from "react";
import { useRoutes } from "react-router-dom";

import routes from "routes";
import PageLoading from "pages/PageLoading/PageLoading";
import OfflineHandle from "components/OfflineHandle";
import useWeb3Config from "hooks/useWeb3Config";
import ServiceGA4 from "services/ga4.service";
// import { isDev } from "env/env";

function App() {
  useWeb3Config(true); // init web3Config;
  const element = useRoutes(routes);

  ServiceGA4.init();
  window.onpageshow = function (event) {
    if (event.persisted) window.location.reload();
  };


  React.useEffect(() => {
    (async () => {
      // for test
      // isDev && console.log("[INFO][App::useWeb3Config] setup Web3, provider:", (window as any).ethereum);
    })();
  }, []);

  return (<>
    <OfflineHandle />
    <React.Suspense fallback={<PageLoading />}>
      {element}
    </React.Suspense>
  </>
  );
}

export default App;