import { Blockchain } from "redux/types/web3Config";
import { ASYNC_STATUS_LOADED } from "types/asyncStatus";

export const DEFAULT_BLOCKCHAIN: Blockchain = {
  // isConnected: false,
  isConnected: (window as any).ethereum
    ? (window as any).ethereum.isConnected === undefined
      ? true
      : (window as any).ethereum.isConnected()
    : false,
  asyncStatusConnectChain: ASYNC_STATUS_LOADED,

  chainID: null,
  networkID: null,
  networkName: null,
  isSupported: false,
  asyncStatusGetChain: ASYNC_STATUS_LOADED, // [TODO] asyncStatusSwitchChain
};