
import { EnumNetworkID } from "blockchains/enums/networkIDs.enum";
import { SupportedChainInfo } from "blockchains/types/blockchain.types";

import ServiceFormat from "services/format.service";

export const networkNameByNetworkID: Record<EnumNetworkID, string> = {
  [EnumNetworkID.Mainnet]: "Ethereum Mainnet",
  // 2: "Ethereum Modern",
  // 3: "Ropsten Testnet",
  // 4: "Rinkeby Testnet",
  [EnumNetworkID.Goerli]: "Goerli Testnet",
  [EnumNetworkID.Sepolia]: "Sepolia Testnet",
  // 42: "Kovan Testnet",
  [EnumNetworkID.ThunderCoreTestnet]: "ThunderCore Testnet",
  [EnumNetworkID.ThunderCoreMainnet]: "ThunderCore Mainnet",
  [EnumNetworkID.Polygon]: "Polygon Mainnet",
  [EnumNetworkID.PolygonMumbai]: "Polygon Mumbai",
  [EnumNetworkID.Bsc]: "Binance Smart Chain Mainnet",
  [EnumNetworkID.BscTestnet]: "Binance Smart Chain Testnet",
  [EnumNetworkID.Unknown]: "Unknown"
};

export const chainInfoByNetworkID: Record<number, SupportedChainInfo> = {
  [EnumNetworkID.Mainnet]: {
    chainId: ServiceFormat.toHexStringFromDecimal(EnumNetworkID.Mainnet),
    chainName: networkNameByNetworkID[EnumNetworkID.Mainnet],
    rpcUrls: [],
    blockExplorerUrls: [],
  },
  [EnumNetworkID.Goerli]: {
    chainId: ServiceFormat.toHexStringFromDecimal(EnumNetworkID.Goerli),
    chainName: networkNameByNetworkID[EnumNetworkID.Goerli],
    rpcUrls: [],
    blockExplorerUrls: [],
  },
  [EnumNetworkID.ThunderCoreTestnet]: { // thunder-testnet
    chainId: ServiceFormat.toHexStringFromDecimal(EnumNetworkID.ThunderCoreTestnet),
    chainName: networkNameByNetworkID[EnumNetworkID.ThunderCoreTestnet],
    rpcUrls: ["https://testnet-rpc.thundercore.com"],
    blockExplorerUrls: ["https://explorer-testnet.thundercore.com"],
  },
  [EnumNetworkID.ThunderCoreMainnet]: { // thunder-mainnet
    chainId: ServiceFormat.toHexStringFromDecimal(EnumNetworkID.ThunderCoreMainnet),
    chainName: networkNameByNetworkID[EnumNetworkID.ThunderCoreMainnet],
    rpcUrls: ["https://mainnet-rpc.thundercore.com"],
    blockExplorerUrls: ["https://viewblock.io/thundercore"],
  },
};