export type AsyncStatus = {
  message: string;
  hasError: boolean;
  loading: boolean;
};

export const ASYNC_STATUS_LOADED: AsyncStatus = {
  message: "",
  hasError: false,
  loading: false
};

export const ASYNC_STATUS_LOADING: AsyncStatus = {
  message: "",
  hasError: false,
  loading: true
};

export const ASYNC_STATUS_ERROR: AsyncStatus = {
  message: "",
  hasError: true,
  loading: false
};

export const ASYNC_STATUS_SUCCESS = ASYNC_STATUS_LOADED;