

import ServiceFormat from "services/format.service";
import { ServiceType } from "services/type.service";

export enum EnumNetworkID {
  // Ethereum
  Mainnet = 1,
  DollyTestnet = 1,
  Goerli = 5,
  Sepolia =  11155111,
  // ThunderCore
  ThunderCoreTestnet = 18,
  ThunderCoreMainnet = 108,
  // Polygon
  Polygon = 137,
  PolygonMumbai = 80001,
  // BSC
  Bsc = 56,
  BscTestnet = 97,
  // Unknown
  Unknown = 0
}

export const IsEnumNetworkID = (input: any): input is EnumNetworkID => {
  if (ServiceType.isString(input)) {
    input = ServiceFormat.toNumber(input);
  }

  return Object.values(EnumNetworkID).includes(input) ? true : false;
};
