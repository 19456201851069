import React from "react";
import { css, cx } from "@emotion/css";

import { styleSettingHeight, styleSettingColor } from "DollyApp/styles/variables.style";

import useScrollHandler from "hooks/useScrollHandler";
import useInnerHeight from "hooks/useInnerHeight";

import Header from "./components/Header";

type Props = {
  children?: React.ReactNode;
  mainClassName?: string;
  layoutClassName?: string;
};

const LayoutApp = (props: Props) => {
  useScrollHandler();
  const [innerHeight] = useInnerHeight();

  return (
    <div className={cx("DD-LayoutApp", style(innerHeight), props.layoutClassName)}>
      <Header />
      <main id="websiteTop" className={props.mainClassName}>
        {props.children}
      </main>
    </div>
  );
};

export default LayoutApp;

const style = (_innerHeight: number) => css`
  background-color: ${styleSettingColor.bg};
  color: ${styleSettingColor.text.primary};
  
  main {
    min-height: calc(${_innerHeight}px - ${styleSettingHeight.header});
    padding-bottom: 80px;
    box-sizing: border-box;
  }
`;