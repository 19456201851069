import React from "react";
import { useLocation } from "react-router-dom";

import LayoutHome from "DollyApp/layouts/LayoutHome";
import LayoutApp from "DollyApp/layouts/LayoutApp";
import { pageLinks } from "DollyApp/routes";

type PropsBody = {
  children: JSX.Element;
};

const Body = (props: PropsBody) => {
  const location = useLocation();

  if ([
    pageLinks.home,
    pageLinks.referralReferee,
    pageLinks.referralReferrerPreview
  ].includes(location.pathname)) {
    return <LayoutHome>{props.children}</LayoutHome>;
  }

  return <LayoutApp>{props.children}</LayoutApp>;
};

export default Body;