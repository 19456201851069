import React from "react";

import routesDollyApp from "DollyApp/routes";
import DollyApp from "DollyApp/DollyApp";
// import { PAGE_LINKS_DOLLY_APP } from "DollyApp/constants";

// const DollyApp = React.lazy(() => import(/* webpackChunkName: "DollyAdmin" */"DollyAdmin/DollyAdmin"));
const DollyAdmin = React.lazy(() => import(/* webpackChunkName: "DollyAdmin" */"DollyAdmin/DollyAdmin"));
const NotFound = React.lazy(() => import(/* webpackChunkName: "NotFound" */"pages/NotFound/NotFound"));
// const Maintenance = React.lazy(() => import(/* webpackChunkName: "Maintenance" */"pages/Maintenance/Maintenance"));

  const routes = [
    {
      path: "",
      element: <DollyApp />,
      children: routesDollyApp
    },
    {
      path: "admin",
      element: <DollyAdmin />
    },
    // {
    //   path: "",
    //   element: <Maintenance />,
    // },
    // {
    //   path: `/${PAGE_LINKS_DOLLY_APP.stake}`,
    //   element: <Maintenance />,
    // },
    {
      path: "*",
      element: <NotFound />
    }
  ];

export default routes;