import { isDev } from "env/env";

export enum LogLevel {
  Debug = 1,
  Disabled = 99,
  Error = 41,
  Info = 21,
  Log = 11,
  Warn = 31
}

export const IsLogLevel = (input: any): input is LogLevel => {
  return Object.values(LogLevel).includes(input) ? true : false;
};

let _logLevel = LogLevel.Log;
let _logLevelText: {[key in LogLevel]: string} = {
  [LogLevel.Debug]: "DEBUG",
  [LogLevel.Disabled]: "DISABLED",
  [LogLevel.Error]: "ERROR",
  [LogLevel.Info]: "INFO",
  [LogLevel.Log]: "LOG",
  [LogLevel.Warn]: "WARN"
};

const _getLogLevelValueByKey = (value: string) => {
  const indexOfS = Object.values(LogLevel).indexOf(value as unknown as LogLevel);
  const key = Object.keys(LogLevel)[indexOfS];
  return key;
};

const _toLogText = (logType: LogLevel, logMsg: string, logParams?: {[key: string]: any}, logModule?: string, logFunc?: string) => {
  const logText: Array<string> = [];
  logText.push(`[${_logLevelText[logType]}]`);

  if (logModule) {
    if (logFunc) {
      logText.push(`[${logModule}::${logFunc}]`);
    } else {
      logText.push(`[${logModule}]`);
    }
  }

  logText.push(` ${logMsg}`);

  if (logParams) {
    for (const k in logParams) {
      if (logParams[k] && logParams[k] instanceof Error) {
        logParams[k] = logParams[k].message;
      }
    }

    logText.push(` [params: ${JSON.stringify(logParams)}]`);
  }

  return logText.join("");
};


const debug = (logMsg: string, logParams?: object, logModule?: string, logFunc?: string) => {
  if (!isDev) return;

  if (_logLevel > LogLevel.Debug) {
    return;
  }

  console.trace(_toLogText(LogLevel.Debug, logMsg, logParams, logModule, logFunc));
};

const error = (logMsg: string, logParams?: object, logModule?: string, logFunc?: string) => {
  if (!isDev) return;

  if (_logLevel > LogLevel.Error) {
    return;
  }

  console.error(_toLogText(LogLevel.Error, logMsg, logParams, logModule, logFunc));
};

const info = (logMsg: string, logParams?: object, logModule?: string, logFunc?: string) => {
  if (!isDev) return;

  if (_logLevel > LogLevel.Info) {
    return;
  }

  console.info(_toLogText(LogLevel.Info, logMsg, logParams, logModule, logFunc));
};

const log = (logMsg: string, logParams?: object, logModule?: string, logFunc?: string) => {
  if (!isDev) return;

  if (_logLevel > LogLevel.Log) {
    return;
  }

  console.log(_toLogText(LogLevel.Log, logMsg, logParams, logModule, logFunc));
};

const setLevel = (level: LogLevel) => {
  if (!isDev) return;

  if (typeof level === 'string') {
    level = _getLogLevelValueByKey(level) as unknown as LogLevel;
  }

  if (level === _logLevel) {
    return;
  }

  _logLevel = level;
  log(`Loglevel has been set successfully`, {level: _logLevelText[level]}, "Logger", "setLevel");
};

const warn = (logMsg: string, logParams?: object, logModule?: string, logFunc?: string) => {
  if (!isDev) return;
  
  if (_logLevel > LogLevel.Warn) {
    return;
  }

  console.warn(_toLogText(LogLevel.Warn, logMsg, logParams, logModule, logFunc));
};

export const Logger = {
  debug,
  error,
  info,
  log,
  setLevel,
  warn
};
