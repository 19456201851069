import { CurrencyEth, UploadSimple, Book, PiggyBank } from "@phosphor-icons/react";

import ServiceFormat from "services/format.service";

import { MEDIA_SETTING, PAGE_LINKS_DOLLY_APP } from "DollyApp/constants";
import { SidebarMenuItem } from "DollyApp/layouts/components/Sidebar/components/SidebarMenu/SidebarMenu";

const menu: Array<SidebarMenuItem> = [
  {
    label: ServiceFormat.toUppercaseFirstLetter(PAGE_LINKS_DOLLY_APP.stake),
    path: PAGE_LINKS_DOLLY_APP.stake,
    icon: <PiggyBank />,
  },
  {
    label: ServiceFormat.toUppercaseFirstLetter(PAGE_LINKS_DOLLY_APP.rewards),
    path: PAGE_LINKS_DOLLY_APP.rewards,
    icon: <CurrencyEth />,
  },
  // {
  //   label: "Referral",
  //   path: PAGE_LINKS_DOLLY_APP.referralReferrerPreview,
  //   icon: <Person />,
  // },
  {
    label: ServiceFormat.toUppercaseFirstLetter(PAGE_LINKS_DOLLY_APP.unstake),
    path: PAGE_LINKS_DOLLY_APP.unstake,
    icon: <UploadSimple />,
  },
  {
    label: MEDIA_SETTING.gitBook.name,
    link: MEDIA_SETTING.gitBook.link,
    isBlank: true,
    icon: <Book />,
  }
];

export default menu;
