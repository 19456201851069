import { AxiosResponse } from "axios";

import FactoryDolly from "DollyApp/resources/dolly.factory";
import ServiceAPI from "services/api.service";

const getBasicInfo = async () => {
  const response: AxiosResponse<any> = await ServiceAPI.get(`/Dolly/getBasicInfo`);
  return FactoryDolly.createBasicInfoFromAPI(response.data);
};

const getUserInfo = async (userAddress: string) => {
  const response: AxiosResponse<any> = await ServiceAPI.get(`/Dolly/getUserInfo`, {
    params: { _U: userAddress }
  });
  return FactoryDolly.createUserInfoFromAPI(response.data);
};

const getPayoutHistory = async () => {
  const response: AxiosResponse<any> = await ServiceAPI.get(`/Dolly/getPayoutHistory`);
  return FactoryDolly.createPayoutHistoryFromAPI(response.data.data);
};

const getReferrerInfo = async (referrerAddress: string) => {
  const response: AxiosResponse<any> = await ServiceAPI.get(`/Dolly/getReferrerInfo`, {
    params: { _U: referrerAddress }
  });
  return FactoryDolly.createReferrerInfoFromAPI(response.data);
};

const getReferralRewardInfo = async (referrerAddress: string) => {
  const response: AxiosResponse<any> = await ServiceAPI.get(`/Dolly/getReferralRewardInfo`, {
    params: { _U: referrerAddress }
  });

  return FactoryDolly.createReferralRewardInfoFromAPI(response.data);
};

const ResourceDolly = {
  getBasicInfo,
  getUserInfo,
  getPayoutHistory,
  getReferrerInfo,
  getReferralRewardInfo
};

export default ResourceDolly;