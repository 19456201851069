import { ASYNC_STATUS_ERROR, ASYNC_STATUS_LOADED, ASYNC_STATUS_LOADING } from "types/asyncStatus";
import { DEFAULT_BLOCKCHAIN } from "redux/constant/web3Config";
import { EnumTypeAccount, EnumTypeBalance, EnumTypeConnectChain, EnumTypeConnectWallet, EnumTypeGetChain, EnumTypeMessage, EnumTypeWeb3 } from "redux/enums/web3Config";
import { Action, Web3Config } from "redux/types/web3Config";

export const DEFAULT_WEB3_CONFIG: Web3Config = {
  provider: (window as any).ethereum,
  blockchain: DEFAULT_BLOCKCHAIN,
  userAddress: null,
  asyncStatusGetUserAddress: ASYNC_STATUS_LOADED,
  asyncStatusConnectWallet: ASYNC_STATUS_LOADED,

  balance: 0,
  asyncStatusGetBalance: ASYNC_STATUS_LOADED,
  message: {
    type: "",
    data: ""
  }
};

export const web3ConfigReducer = (state: Web3Config = DEFAULT_WEB3_CONFIG, action: Action): Web3Config => {
  switch (action.type) {
    case EnumTypeConnectChain.CONNECT_CHAIN_LOADED:
      return {
        ...state,
        blockchain: { ...state.blockchain, ...action.payload, asyncStatusConnectChain: ASYNC_STATUS_LOADED },
      };
    case EnumTypeConnectChain.CONNECT_CHAIN_ERROR:
      return {
        ...state,
        blockchain: {
          ...state.blockchain,
          asyncStatusConnectChain: {
            ...ASYNC_STATUS_ERROR,
            message: action.payload,
          },
        },
      };

    case EnumTypeGetChain.GET_CHAIN_LOADING:
      return {
        ...state,
        blockchain: { ...state.blockchain, asyncStatusGetChain: ASYNC_STATUS_LOADING },
      };
    case EnumTypeGetChain.GET_CHAIN_LOADED:
      return {
        ...state,
        blockchain: { ...state.blockchain, ...action.payload, asyncStatusGetChain: ASYNC_STATUS_LOADED },
      };
    case EnumTypeGetChain.GET_CHAIN_ERROR:
      return {
        ...state,
        blockchain: { ...state.blockchain, asyncStatusGetChain: { ...ASYNC_STATUS_ERROR, message: action.payload } },
      };

    case EnumTypeAccount.ACCOUNT_LOADING:
      return { ...state, asyncStatusGetUserAddress: ASYNC_STATUS_LOADING };
    case EnumTypeAccount.ACCOUNT_LOADED:
      return { ...state, userAddress: action.payload, asyncStatusGetUserAddress: ASYNC_STATUS_LOADED };
    case EnumTypeAccount.ACCOUNT_ERROR:
      return { ...state, asyncStatusGetUserAddress: { ...ASYNC_STATUS_ERROR, message: action.payload } };

    case EnumTypeConnectWallet.CONNECT_WALLET_LOADING:
      return { ...state, asyncStatusConnectWallet: ASYNC_STATUS_LOADING };
    case EnumTypeConnectWallet.CONNECT_WALLET_LOADED:
      return { ...state, asyncStatusConnectWallet: ASYNC_STATUS_LOADED, userAddress: action.payload };
    case EnumTypeConnectWallet.CONNECT_WALLET_ERROR:
      return { ...state, asyncStatusConnectWallet: { ...ASYNC_STATUS_ERROR, message: action.payload } };

    case EnumTypeBalance.BALANCE_LOADING:
      return { ...state, asyncStatusGetBalance: ASYNC_STATUS_LOADING };
    case EnumTypeBalance.BALANCE_LOADED:
      return { ...state, asyncStatusGetBalance: ASYNC_STATUS_LOADED, balance: action.payload };
    case EnumTypeBalance.BALANCE_ERROR:
      return { ...state, asyncStatusGetBalance: { ...ASYNC_STATUS_ERROR, message: action.payload } };

    case EnumTypeMessage.MESSAGE_LOADED:
      return { ...state, message: action.payload };

    case EnumTypeWeb3.WEB3_RESET:
      return DEFAULT_WEB3_CONFIG;
    default:
      return state;
  }
};
