import ServiceFormat from "services/format.service";

export type ErrorContract = {
  code: number;
  message: string;
};

export const IsErrorContract = (error: any): error is ErrorContract => {
  return error.code && error.message ? true : false;
};

export const ParseErrorContract = (e: any): ErrorContract | undefined => {
  if (!e.message) {
    return;
  }

  if (typeof e.message !== 'string') {
    return;
  }

  return {
    code: ServiceFormat.toNumber(e.code),
    message: ServiceFormat.toString(e.message)
  };
};
