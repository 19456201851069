import { PAGE_LINKS_DOLLY_APP_REFERRAL } from "DollyApp/modules/referral/constants";
import logo from "assets/logo.png";

export const PAGE_LINKS_DOLLY_APP = {
  home: "home",
  policy: "policy",
  term: "term",
  stake: "stake",
  unstake: "unstake",
  rewards: "rewards",
  ...PAGE_LINKS_DOLLY_APP_REFERRAL
};

export const MEDIA_SETTING = {
  twitter: {
    name: "Giveaway",
    link: "https://twitter.com/DefiDolly",
  },
  discord: {
    name: "Discord",
    link: "https://discord.gg/aCXnbxYQXN",
  },
  telegram: {
    name: "Telegram",
    link: "",
  },
  linktree: {
    name: "Linktree",
    link: "https://linktr.ee/defidolly"
  },
  medium: {
    name: "Medium",
    link: "",
  },
  gitBook: {
    name: "FAQ",
    link: "https://docs.defidolly.org/faq",
  },
};

export const METADATA_SETTING = {
  title: "DefiDolly - Maximize ETH income",
  description: `Magnify funds with 10x leverage. This is a LSD leverage platform. Stake ETH or stETH to get ETH passive income from Ethereum POS staking. After Ethereum Shanghai upgrade you can get ETH rewards by staking ETH. When you stake 1 ETH, DefiDolly will leverage it into 10 ETH with Lido, Compound V3, Balancer to maximize your ETH rewards. This is a LSD leverage platform based on Lido. Stake, stable, chill. What can users do with their steth tokens lido, lido finance, lido staking, lido eth, lido dao, lido stETH, stETH bybit, binance earn, the merge, Lido Arbitrum, Farmer Crypto, yield farming 2023, Defi history, Where does the majority of yield come from when liquidity mining, liquidity mining, crypto mining, how to earn passive income, earn crypto, staking income, coinbase earn, crypto rewards, pow pos, pow vs pos, curve, reth, uniswap, makerdao, oasis.app, summer fi, lybra finance, pendle, pendle finance, pendle crypto
  `,
  mediaResource: logo,
  favicon: logo,
  hubURL: "https://defidolly.org/",
  image: "https://defidolly.org/defidolly.png?v=2023-08-03"
};

export const SPECIAL_REGULATION = {
  decimalPlaceOfAmount: 4,
  decimalPlaceOfInput: 5,
};