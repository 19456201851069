import React from "react";
import { Link } from "react-router-dom";
import { css } from "@emotion/css/macro";
import { Button } from "@mui/material";

import { pageLinks } from "DollyApp/routes";
import repairShape1 from "../assets/MainBanner/repair-shape1.png";
import repairShape2 from "../assets/MainBanner/repair-shape2.png";
import repairShapeCircle from "../assets/MainBanner/repair-shape-circle.png";
import { ReactComponent as ETHBanner } from "../assets/ETHBanner.svg";

const MainBanner = () => {
  return (
    <div className={style()}>
      <div className="main-banner">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="row h-100 justify-content-center align-items-center">
                <div className="col-lg-5">
                  <div className="hero-content">
                    <h1>
                      Maximize
                      <div className="text-anime">
                        <span className="stETH">stETH</span>
                        <span className="ETH">ETH</span>
                      </div>
                      <span className="rewards-title">rewards</span>
                    </h1>
                    <p>
                      When you stake 1 ETH, DefiDolly will help you leverage it
                      into 10 ETH with Balancer, Compound, Lido to maximize your
                      rewards.
                    </p>

                    <Button
                      variant="contained"
                      className="dd-gradient-button header-to-stake"
                      component={Link}
                      to={pageLinks.stake}
                    >
                      Stake
                    </Button>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="repair-banner-image">
                    <ETHBanner className="img" />

                    <img
                      src={repairShape1}
                      className="animate__animated animate__zoomIn animate__delay-0.6s"
                      alt="repairShape1"
                    />

                    <img
                      src={repairShape2}
                      className="animate__animated animate__fadeInLeft animate__delay-0.6s"
                      alt="repairShape2"
                    />

                    <img
                      src={repairShapeCircle}
                      className="rotateme"
                      alt="repairShapeCircle"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;

const style = () => css`
  .d-table {
    height: 80%;
  }
  .hero-content {
    h1 span {
      color: #dc748d;
      display: inline-block;
    }
    .text-anime {
      display: inline-block;
      margin: 0 auto;
      .stETH {
        animation: show 3s infinite;
      }
      .ETH {
        right: 50%;
        position: relative;
        animation: try 3s infinite;
      }
    }
    .rewards-title {
      color: #0e314c;
    }
    .header-to-stake {
      font-size: 1rem;
      width: 10rem;
      height: 3rem;
      margin-left: auto;
    }
  }
  .header-link {
    color: white;
  }

  .repair-banner-image {
    .img {
      height: 45%;
      width: 45%;
    }
  }

  @media screen and (max-width: 991px) {
    .rewards-title {
      margin-left: -50px;
    }
    .col-lg-6 {
      margin-top: 8rem;
    }
    .repair-banner-image {
      .img {
        height: 35%;
        width: 35%;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .rewards-title {
      margin-left: -50px;
    }
    .col-lg-6 {
      margin-top: 0rem;
    }
    .repair-banner-image {
      .img {
        height: 45%;
        width: 45%;
      }
    }
  }

  @keyframes try {
    0% {
      opacity: 0;
      transform: translate(0px, -40%);
    }
    15% {
      opacity: 1;
      transform: translate(0px, 0%);
    }
    50% {
      opacity: 1;
      transform: translate(0px, 0%);
    }
    65% {
      opacity: 0;
      transform: translate(0px, 40%);
    }
    100% {
      opacity: 0;
      transform: translate(0px, 40%);
    }
  }

  @keyframes show {
    0% {
      opacity: 1;
      transform: translate(0px, 0%);
    }
    15% {
      opacity: 0;
      transform: translate(0px, 40%);
    }
    50% {
      opacity: 0;
      transform: translate(0px, -40%);
    }
    65% {
      opacity: 1;
      transform: translate(0px, 0%);
    }
    100% {
      opacity: 1;
      transform: translate(0px, 0%);
    }
  }
`;
