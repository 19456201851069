import React from "react";
import { css } from "@emotion/css";

import balancer from '../public/images/partner-img/balancer.png';
import compound from '../public/images/partner-img/compound.png';
import uniswap from '../public/images/partner-img/uniswap.png';
import lido from '../public/images/partner-img/lido.png';

const Partner = () => {

	return (
		<div className={style()}>
			<div className="ready-to-talk">
				<div className="container">
					<h3>What protocol does DefiDolly use?</h3>
					<h5>Popular & Secure Protocols</h5>
				</div>
			</div>

			<div className="partner-area partner-section">
				<div className="container">
					<div className="partner-inner">
						<div className="row justify-content-center">
							<div className="col-lg-2 col-md-3 col-6">
									<img src={balancer} alt="partner" />
							</div>
							<div className="col-lg-2 col-md-3 col-6">
									<img src={compound} alt="partner" />
							</div>

							<div className="col-lg-2 col-md-3 col-6">
									<img src={uniswap} alt="partner" />
							</div>

							<div className="col-lg-2 col-md-3 col-6">
									<img src={lido} alt="partner" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Partner;

const style = () => css`
	.ready-to-talk {
		background: linear-gradient(132.26deg, #8a2f94 0%, #f2868a 100%);
		h5 {
			color: white;
		}
	}

	.col-lg-2.col-md-3.col-6 {
		a {
			pointer-events: none;
		}
	}
`;