import React from "react";
import { RouteObject } from "react-router-dom";

import { PAGE_LINKS_DOLLY_APP_REFERRAL } from "../constants";
import { EnumReferralType } from "../enums/EnumReferralType";

const Referral = React.lazy(() => import("../pages/Referral/Referral"));

const routesReferral: Array<RouteObject> = [
  {
    path: PAGE_LINKS_DOLLY_APP_REFERRAL.referralReferrerPreview,
    element: <Referral referralType={EnumReferralType.Referrer} />
  },
  {
    path: PAGE_LINKS_DOLLY_APP_REFERRAL.referralReferee,
    element: <Referral referralType={EnumReferralType.Referee} />
  }
];

export default routesReferral;
