import { EnumNetworkID } from "blockchains/enums/networkIDs.enum";
import { EnumEnv, IsEnumEnv } from "enums/env.enum";

type EnvSetting = {
  originAPI: string;
  rpcURL: string;
  supportedNetworkIDs: EnumNetworkID[];
  hubURL: string;
  evaluationID: string;
};

export const ALL_ENV_SETTING: Record<EnumEnv, EnvSetting> = {
  [EnumEnv.Development]: {
    originAPI: "https://defi-dolly-backend-test-f71ebed81900.herokuapp.com",
    rpcURL: "https://rpc.tenderly.co/fork/12f94293-89c9-4624-af4c-8b30ad80c992", // dolly testnet // unused
    supportedNetworkIDs: [EnumNetworkID.DollyTestnet, EnumNetworkID.Goerli, EnumNetworkID.Sepolia],
    hubURL: "https://defi-dolly-test.herokuapp.com",
    evaluationID: "G-M1CHDN8F3J",
  },
  [EnumEnv.Production]: {
    originAPI: `https://defi-dolly-backend-4f1c2e09a0b6.herokuapp.com`,
    rpcURL: "",
    supportedNetworkIDs: [EnumNetworkID.Mainnet],
    hubURL: "https://defidolly.org",
    evaluationID: "G-0NWENSF33G",
  },
};

export const getENV = () => {
  let env: EnumEnv = EnumEnv.Development;

  if (IsEnumEnv(process.env.REACT_APP_ENV)) {
    env = process.env.REACT_APP_ENV;
  }

  return env;
};

export const isDev = getENV() === EnumEnv.Development;

const env = getENV();

export default ALL_ENV_SETTING[env];
